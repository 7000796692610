import "./Offers.scss"

import HappyUser from "../../assets/model.jpeg"

import { standard, priority, committed }from "../../data/plans"

function Offers () {

    const name = "Carrefour Télécoms & Services"
    const nameArray = name.split("")

    return (
        

        <main>
            <section className="offers">
                <header className="offers__header container">
                    <div className="offers__header__image">
                        <img src={ HappyUser } alt="Happy user" />
                    </div>

                    <div className="offers__header__content">
                        <h2>
                            Le choix de la connexion internet<span className="important"> haut débit</span>
                        </h2>
                        <p>
                            Nous vous offrons la connexion haut débit, accessible où que vous soyez
                            avec des forfaits performants pour des applications métiers. Découvrez
                            nos offres et les types de plan proposés.
                        </p>
                        <a href="https://api.whatsapp.com/send?phone=0022959596350">Souscrire</a>
                    </div>

                    <div className="offers__header__circle">
                        <div className="offers__header__circle__logo"></div>
                        <div className="offers__header__circle__text">
                            <h1>
                                {nameArray.map((char, index) =>{
                                    return (
                                        <span key={index} 
                                        style={
                                            {transform: `rotate(${index * 12}deg)`}
                                        }>
                                            {char}
                                        </span>
                                    )
                                })}
                            </h1>
                        </div>
                    </div>
                </header>

                <article className="offers__container container">
                    <h1>Découvrez nos offres</h1>
                    <section className="standard">
                        <h2>Standard</h2>
                        <article className="plan">
                        {standard.map((plan, index) =>{
                            return(
                                <div key={index}>
                                    <h1>{plan.title}</h1>
                                    <p>Volume mensuel : {plan.volume}</p>
                                    <p>Vitesse en débit : {plan.flow}</p>
                                    <p>Nuit illimitée : {plan.night}</p>
                                </div>
                            )
                        })}
                        </article>
                    </section>
                    <section className="priority">
                        <h2>Advance Priority</h2>
                        <article className="plan">
                        {priority.map((plan, index) =>{
                            return(
                                <div key={index}>
                                    <h1>{plan.title}</h1>
                                    <p>Vitesse en débit : {plan.flow}</p>
                                </div>
                            )
                        })}
                        </article>
                    </section>
                    <section className="committed">
                        <h2>Advance committed</h2>
                        <article className="plan">
                        {committed.map((plan, index) =>{
                            return(
                                <div key={index}>
                                    <h1>{plan.title}</h1>
                                    <p>Vitesse en débit : {plan.speed}</p>
                                    <p>Débit garanti : {plan.flow}</p>
                                </div>
                            )
                        })}
                        </article>
                    </section>
                </article>
            </section>
        </main>
    )
}

export default Offers
