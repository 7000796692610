export const standard = [
    {
        "id": "ksplan50",
        "title": "Plan 50",
        "volume": "50 Go",
        "flow": "20/3 Mbps",
        "night": "300Go/mois offert de 22h à 6h"
    },
    {
        "id": "ksplan100",
        "title": "Plan 100",
        "volume": "100 Go",
        "flow": "25/3 Mbps",
        "night": "300Go/mois offert de 22h à 6h"
    },
    {
        "id": "ksplan250",
        "title": "Plan 250",
        "volume": "250 Go",
        "flow": "25/3 Mbps",
        "night": "300Go/mois offert de 22h à 6h"
    }
]

export const priority = [
    {
        "id": "kapplan10",
        "title": "Priority 10",
        "flow": "10/3 Mbps"
    },
    {
        "id": "kapplan25",
        "title": "Priority 25",
        "flow": "25/3 Mbps"
    },
    {
        "id": "kapplan50",
        "title": "Priority 50",
        "flow": "50/5 Mbps"
    }
]

export const committed = [
    {
        "id": "kacplan10",
        "title": "Committed 10",
        "speed": "10/3 Mbps",
        "flow": "3/1 Mbps"
    },
    {
        "id": "kacplan25",
        "title": "Committed 25",
        "speed": "25/3 Mbps",
        "flow": "5/1 Mbps"
    },
    {
        "id": "kacplan50",
        "title": "Committed 50",
        "speed": "50/3 Mbps",
        "flow": "10/2 Mbps"
    },
    {
        "id": "kacplan50+",
        "title": "Committed 50+",
        "speed": "50/5 Mbps",
        "flow": "15/3 Mbps"
    }
]