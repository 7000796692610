import "./Collapse.scss"

import { useState } from 'react'

import { BiPlusCircle } from "react-icons/bi"
import { AiOutlineMinusCircle } from "react-icons/ai"


function Collapse ({ question, response }) {

    const [isOpen, setIsOpen] = useState(false)

    return isOpen ? (
        <section className="faq__container" >
            <header className="faq__question"  onClick={() => setIsOpen(false)}>
                <h1>{question}</h1>
                <AiOutlineMinusCircle className="faq__display"/>
            </header>
            <article className="faq__response" id="open">
                <p>{ response }</p>
            </article>
        </section>
    ) : (
        <section className="faq__container">
            <header className="faq__question" onClick={() => setIsOpen(true)}>
                    <h1>{question}</h1>
                    <BiPlusCircle className="faq__display"/>
            </header>
         </section>
    )
}

export default Collapse
