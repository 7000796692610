import './Home.css';

import { testimonialsData } from "../../data/testimonials"

import Welcome from "../../components/Welcome/Index"
import Band from "../../components/Band/Index"
import Difference from "../../components/Difference/Index"
import Testimonial from "../../components/Testimonial/Index"
import Contact from "../../components/Contact/Index"

function Home() {
  return (
    <main className="main">
      <Welcome />
      <Band />
      <Difference />
      <Testimonial testimonials ={ testimonialsData }/>
      <Contact />
    </main>
  );
}

export default Home;
