import "./About.scss"

import Arrow from "../../assets/otherArrow.svg"

const About = () => {
    return (
        <main className="about">
            <section className="about__header container">
                <div className="about__arrow">
                    <img src={ Arrow } alt="Arrow for about us" />
                </div>
                <header className="about__who">
                    <h1>Carrefour <br />Télécoms et Services</h1>
                    <p>La révolution de l'internet <span className="important">haut débit par satellite</span></p>
                </header>
                <article className="about__what">
                    <p>
                        Partenaire officiel de EUTELSAT, le leader européen de l'internet haut débit par satellite
                        présent dans 40 pays en Afrique sub-saherienne, Carrefour Télécoms et Services rend l'internet
                        disponible partout au Bénin.
                    </p>
                </article>
            </section>

            

            <section className="about__how container">
                <h2>Ensemble pour une meilleur <br />expérience de navigation</h2>
                <article>
                    <p className="introduce">
                        Plus qu'un choix, la connexion internet haut débit s'impose dans notre quotidien comme une nécessité
                        aussi bien pour des besoins personnels que professionnels.
                    </p>
                    <p className="develop">
                        À cet effet, CTS Bénin propose des forfaits volumes à partir de 25 Go avec des débits allant jusqu'à
                        100 Mbps en descente et 5 Mbps en montée. Nos offres sur-mesures sont conçues pour répondre aux applications
                        de type B2B et B2C.<br />
                        Konnect par EUTELSAT est une expérience de près de deux décenies dans la fourniture d'internet via satellite.
                        Nos partenaires et nous travaillons continuellement sur l'amélioration de l'offre de service avec notamment
                        le lancement à venir de 6 satellite pour atteindre notre objectif d'amélioration des systèmes (x20), 
                        faisant passer le débit à 200 Mbps.
                    </p>
                </article>
            </section>

            <section className="about__those container">
                <article>
                    <h2>Konnect est un satellite haut débit HTS avec une vitesse de téléchargement élevée</h2>
                    <p>100Mbps</p>
                </article>
                <article>
                    <h2>L'offre Konnect est présente dans de nombreux pays en Afrique sub-sahérienne</h2>
                    <p>40+</p>
                </article>
                <article>
                    <h2>Une expérience de plusieurs années dans la fourniture d'internet par satellite</h2>
                    <p>20+</p>
                </article>
            </section>
        </main>
    )
}

export default About
