export const faqs = [
    {
        "id": "00001",
        "question": "Comment obtenir un accès internet haut débit ?",
        "response": 'Pour profiter d’un accès à Internet haut débit, le client aura besoin d\'un équipement. Pour garantir la rapidité de \'installation, celle-ci doit être effectuée par un professionnel. Le kit est composé de : Antenne parabolique, Amplificateur LNB+BUC, "Modem" Hugues HT2000, câble coaxial, etc.',
    },
    {
        "id": "00002",
        "question": "Comment renouveler mon abonnement ?",
        "response": "Les abonnements se renouvellent automatiquement. À cet effet, CTS a mis en place un service de suivi des clients. Chaque client recevra un e-mail quelques jours avant l'expiration de son forfait en cours. Une fois que le client confirme qu'il souhaite renouveler, leplan se poursuivra, sinon le plan sera suspendu jusqu'à ce qu'il nous recontacte pour le réactiver. Mais après 3 mois d'inactivité, nous recontactons le client."
    },
    {
        "id": "00003",
        "question": "Après avoir souscrit à un forfait, peut-on augmenter le débit en cours d'utilisation  ?",
        "response": "Oui celà est possible uniquement pour les forfaits volume (le plan standard). L'option vitesse vous permet de doubler votre  débit initial. Par exemple vous pouvez passer d'un débit de 25Mbps à un débit de 50Mbps en download."
    },
    {
        "id": "00004",
        "question": "Quels sont les avantages de la connexion par VSAT ?",
        "response": "Le VSAT vous permet d'avoir la connexion dans les zones les plus reculées ou pas, les zones non couvertes par la fibre, sa connexion est stable et fiable."
    },
    {
        "id": "00005",
        "question": "Quand on parle du VSAT on voit tout suit une grande installation avec une grosse antenne et de gros moyens pour s'en procurer. Quelle est la taille de vos antennes ?",
        "response": "La taille de l'antenne est de 74cm de diamètre et pèse 12kg. Le kit et l'installation vous reviennent à un prix forfaitaire."
    },
    {
        "id": "00006",
        "question": "Que faire en cas de panne technique ?",
        "response": "En cas de panne technique, veuillez-nous contactez. La maintenance est prise en charge par Cts dans un brefs délai."
    }
]