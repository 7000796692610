import "./Band.scss"

import Wekonnect from "../../assets/logo-wekonnect.gif"

const Band = () => {

    return (
        <section className="band container">
            <header className="band__header">
                <h1>Partenaire</h1>
                <p>Avec notre partenaire pour un internet haut débit.</p>
            </header>
            <article className="band__article">
                <div className="band__article--image">
                    <img src={ Wekonnect } alt="Logo We konnect" />
                    <img src={ Wekonnect } alt="Logo We konnect" />
                    <img src={ Wekonnect } alt="Logo We konnect" />
                    <img src={ Wekonnect } alt="Logo We konnect" />
                    <img src={ Wekonnect } alt="Logo We konnect" />
                    <img src={ Wekonnect } alt="Logo We konnect" />
                </div>
            </article>
        </section>
    )
}

export default Band

