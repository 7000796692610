import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import './style.css';
import Header from './Layouts/Header/Index';
import Footer from './Layouts/Footer/Index';
import Home from './Pages/Home/Index';
import Offers from './Pages/Offers/Index';
import About from './Pages/About/Index';
import Faq from './Pages/Faq/Index';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/nos-offres" element={<Offers />} />
        <Route path="/a-propos" element={<About />} />
        <Route path="/faq" element={<Faq />} />
      </Routes>
      <Footer />
    </Router>
  </React.StrictMode>
);


reportWebVitals();
