import "./Difference.scss"

import Arrow from "../../assets/arrow.svg"
import HappyUser from "../../assets/happyUser-3.jpg"

import { TbChartRadar } from "react-icons/tb"
import { MdSpeed } from "react-icons/md"
import { BsRss } from "react-icons/bs"
import { GrStackOverflow } from "react-icons/gr"

const Difference = () => {
    return (
        <section className="difference container">
            <header className="difference__header">
                <div>
                    <h1>
                        Qu'est-ce qui nous différencie des autres fournisseurs d'<span className="important">accès internet ?</span>
                    </h1>

                    <div className="difference__header--arrow">
                        <img src={ Arrow } width="500" height="328" alt="What makes difference" draggable="false"/>
                    </div>
                </div>
            </header>

            <article className="content">
                <div className="content__card speed">
                    < MdSpeed className="content__card--icon"/>
                    <h2>Vitesse</h2>
                    <p>Vitesse de 100 Mbps pour les applications de type B2B et B2C.</p>
                </div>
                <div className="content__card flexibility">
                    < TbChartRadar className="content__card--icon"/>
                    <h2>Flexibilité</h2>
                    <p>Possibilité d'allouer plus de capacité à la région, quand et où la demande est plus forte.</p>
                </div>
                <div className="user">
                    <img src={HappyUser} alt="Un utilisateur heureux" />
                </div>
                <div className="content__card coverage">
                    < BsRss className="content__card--icon"/>
                    <h2>Couverture</h2>
                    <p>Capacité à couvrir les zones inacessibles, les secteurs verticaux de l'industrie, le haut débit maritime et aérien.</p>
                </div>
                <div className="content__card flow">
                    < GrStackOverflow className="content__card--icon"/>
                    <h2>Débit</h2>
                    <p>Des offres sur-mesure avec un débit plus élévé que celui des technologies terrestre.</p>
                </div>
            </article>
        </section>
    )
}

export default Difference
