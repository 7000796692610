import "./Faq.scss"

import Collapse from "../../components/Collapse/Index";

import { faqs } from "../../data/faqs";

const Faq = () => {
    return (
        <main className="faq">
            <section className="container">
                <header className="faq__header non">
                    <span className="faq__btn">FAQs</span>
                    <h1>Questions fréquentes</h1>
                </header>
                <article>
                { faqs.map((faq, index) => {
                    return(
                        <div key={index} className="">
                            <Collapse question={faq.question} response={faq.response} />
                        </div>
                    )
                })}
                </article>
            </section>
        </main>
    )
}

export default Faq
