export const testimonialsData = [
    {
        "id": "0001",
        "name": "Hermane D.",
        "description": "La connexion internet proposée par Carrefour Télécoms Services est très rapide et leurs tarifs sont abordables. Je recommande !",
        "picture": "https://s3-eu-west-1.amazonaws.com/course.oc-static.com/projects/front-end-kasa-project/profile-picture-2.jpg"

    },
    {
        "id": "0002",
        "name": "Ulrich K.",
        "description": "Avec Carrefour Télécoms Service, je navigue sans interruption avec une connexion haut débit. Merci",
        "picture": "https://s3-eu-west-1.amazonaws.com/course.oc-static.com/projects/front-end-kasa-project/profile-picture-1.jpg"
    },
    {
        "id": "0003",
        "name": "Nadège A.",
        "description": "Enfin un opérateur télécom qui propose une connexion rapide même dans les villages reculés. Merci Carrefour Télécoms Services.",
    }
]