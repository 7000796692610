import "./Footer.scss"
import { Link } from "react-router-dom"
import LOGO from "../../assets/logo.png"

import { BsFacebook } from "react-icons/bs"
import { FaInstagram } from "react-icons/fa"
import { AiOutlineWhatsApp } from "react-icons/ai"

function Footer() {
    return (
        <footer className="footer">
            <div className="container footer__container">
                <Link to="/" className="footer__logo" onClick={()=> {window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}>
                    <img src={LOGO} alt="Logo CTS Bénin" />
                </Link>
                <div className="footer__address">
                    <small>Situé à AGLA</small>
                    <small>Lot 3027, Parcelle C</small>
                    <small>AHOGBOHOUE - Cotonou (BENIN)</small>
                </div>
                <div className="footer__contact ">
                    <small>(+229) 59 59 63 50</small>
                    <a href="mailtocontact@cts-benin.com">contact@cts-benin.com</a>
                </div>
            </div>
            <div className="footer__end container">
                <div className="footer__copyright">
                    <small>&copy; 2023 CTS BENIN</small>
                </div>
                <div className="footer__icons">
                    <a href="https://api.whatsapp.com/send?phone=0022959596350" target="_blank" rel="noreferrer">
                        <AiOutlineWhatsApp />
                    </a>
                    <a href="https://m.facebook.com/" target="_blank" rel="noreferrer">
                        <BsFacebook />
                    </a>
                    <a href="https://www.instagram.com/" target="_blank" rel="noreferrer">
                        <FaInstagram />
                    </a>
                </div>
            </div>
        </footer>
    )
}

export default Footer
