import "./Contact.scss"

const Contact = () => {
    return (
        <section className="contact" id="contact">
            <div className="container contact__container">
            <header className="contact__header">
                <h1>Avec CTS Bénin, le haut débit est à votre portée.</h1>
                <p>Avec notre partenaire Eutelsat, nous rendons l'internet
                    disponible sur toute l'étendue du territoire nationale.
                    Pour en savoir plus sur nos offres, contactez-nous.
                </p>
            </header>
            <form action="" className="contact__form">
                <input type="text" name="name" placeholder="Votre nom" />
                <input type="text" name="phone"  placeholder="Votre téléphone"/>
                <textarea name="message" id="message" cols="30" rows="10" placeholder="Votre message"></textarea>
            </form>
            </div>
        </section>
    )
}

export default Contact
