import "./Testimonial.scss"

import { useState } from "react"

import { AiOutlineArrowLeft } from "react-icons/ai"
import { AiOutlineArrowRight } from "react-icons/ai"

const Testimonial = ( { testimonials }) => {
    
    const [current, setCurrent] = useState(0);
    const length = testimonials.length;
      
    /* Fonction appliquée au bouton suivant */
    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    };
      
    /* Fonction appliqué au bouton précédent */
    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    };

    return (
        <section className="slider container">
            <h1>Les avis de nos <span className="important">clients</span></h1>
            <ul></ul>
            {testimonials.flatMap((testimonial, index) => {
                return(
                    <div key={index}>
                        {index === current && (
                        <div className="slider__testimonial">
                            <p>{testimonial.description}</p>
                            <div>
                                <h2>{ testimonial.name }</h2>
                                <img src={ testimonial.picture } alt="Client" />
                            </div>
                        </div>
                        )}
                    </div>
                )}
            )}
            {length > 1 ? (
            <div className="slider__btn">
                <div className="slider__previous" onClick={prevSlide}>
                    <AiOutlineArrowLeft className="slider__previous__icon" />
                </div>
                <div className="slider__next" onClick={nextSlide}>
                    <AiOutlineArrowRight className="slider__next__icon" />
                </div>
            </div>
            ) : null}
        </section>
    )
}

export default Testimonial
