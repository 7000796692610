import "./header.scss"
import { Link } from "react-router-dom"

import { useState } from "react"
import useColorMode from "../../hooks/useColorMode"

import { BsFillSunFill } from "react-icons/bs"
import { BiMoon } from "react-icons/bi"
import LOGO from "../../assets/logo.png"



const Header = () => {

    const [colorMode, setColorMode] = useColorMode()
    const [attribut, setAttribut] = useState("false")

    /**
     * Use to set aria value that allow to show/don't show the menu and scroll to the top
     * @param {NavList ariaExpended value} value 
     */
    const ariaExpanded = (value) => {
        if(value === "false"){
            setAttribut("true")
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
        }else{
            setAttribut("false")
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
        }
    }

    

    return (
        <header className="header home__header">
            <nav className="nav container">
                <Link to="/" className="nav__logo" onClick={()=> {window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}>
                    <img src={LOGO} alt="Logo CTS Bénin" />
                </Link>

                <div className="nav__menu">
                    <div className="nav__list" aria-expanded={ attribut } onClick={()=> ariaExpanded(`${attribut}`)}>
                        <Link to='/' className="nav__item" id="Home" aria-expanded={ attribut } onClick={()=> ariaExpanded(`${attribut}`)}>
                            Accueil
                        </Link>
                        <Link to='/nos-offres' className="nav__item" aria-expanded={ attribut } onClick={()=> ariaExpanded(`${attribut}`)}>
                            Nos offres
                        </Link>
                        <Link to='/a-propos' className="nav__item" aria-expanded={ attribut } onClick={()=> ariaExpanded(`${attribut}`)}>
                            À propos
                        </Link>
                        <Link to='/faq' className="nav__item" aria-expanded={ attribut } onClick={()=> ariaExpanded(`${attribut}`)}>
                            FAQ
                        </Link>
                    </div>

                    {/********* Navigation buttons ******/}
                    {colorMode === 'dark' ? (
                            <button
                            onClick={() => setColorMode(colorMode === 'light' ? 'dark' : 'light')}
                            >
                            <span className="theme-btn to__light"><BsFillSunFill /></span>
                            </button>
                        ) : (
                            <button
                            onClick={() => setColorMode(colorMode === 'light' ? 'dark' : 'light')}
                            >
                            <span className="theme-btn to__dark"><BiMoon /></span>
                            </button>
                    )}

                    {/********* Hamburger Menu for Mobile ******/}
                    <div className="nav__toggle" aria-expanded={ attribut }
                    onClick={()=> ariaExpanded(`${attribut}`)}>
                        <span className="bar"></span>
                        <span className="bar"></span>
                        <span className="bar"></span>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Header
