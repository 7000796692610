import "./Welcome.scss"

import Typical from 'react-typical'

import HappyOne from "../../assets/happyUser-1.jpeg"
import HappyTwo from "../../assets/happyUser-2.jpeg"
import HappyThree from "../../assets/happyUser-4.jpg"



const Welcome = () => {
    return (
        <section className="section container">
            <div className="welcome">
                <div className="welcome__images">
                    <figure className="image image__one">
                        <img src={HappyOne} alt="Happy user at work" />
                        <figcaption><span className="name">Sarah T.</span> <br />Responsable RH</figcaption>
                    </figure>
                    <figure className="image image__two">
                        <img src={HappyTwo} alt="Happy user at home" />
                        <figcaption><span className="name">Amina K.</span> <br />Chargé de communication</figcaption>
                    </figure>
                    <figure className="image image__three">
                        <img src={HappyThree} alt="Happy user in the street" />
                        <figcaption><span className="name">Hugues C.</span> <br />Rédacteur Freelance</figcaption>
                    </figure>
                </div>
                <article className="welcome__text">
                    <div className="wekonnect">
                        <h1>Carrefour Télécoms & Services</h1>
                        <span className="wekonnect__btn">We Konnect !</span>
                    </div>
                    <h2>Votre fournisseur d'accès internet
                        <span className="important"> 
                        <Typical
                        loop={Infinity}
                        steps={['haut débit', 1000, 'haut débit', 1000]}
                        />
                        </span>
                    </h2>
                    <p>
                        Une gamme complète d'offres internet par satellite pour répondre à vos besoins.<br />
                        Des questions ? Nos conseillers vous répondent dans l'heure.
                    </p>
                    <a href="#contact">Nous contacter</a>
                </article>
            </div>
        </section>
    )
}

export default Welcome
